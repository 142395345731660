<template>
  <div>
    <ul v-for="category in categories" :key="category.id">
      <span>{{ category.title }}</span>
      <li v-for="goods in getGoodsInfo(category.id)" :key="goods.goods_seq">
        <router-link :to="`/shop/goods/${goods.goods_seq}`">
          <!-- {{ goods.goods_name }} {{ goods.id }} {{ category.id }} -->
          <p>
            <img :src="goods.image" @error="replaceImg" alt="" width="100" />
          </p>
          <p>{{ goods.goods_name }}</p>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      redata: [],
    };
  },
  async mounted() {
    await this.$store.dispatch("shop/bestGoodList", { level: 2 });
    if (this.result === false) {
      alert(this.msg);
      this.$router.go("/");
    }
  },
  computed: {
    ...mapState("shop", ["categories", "goodsInfo", "msg", "result"]),
    getGoodsInfo() {
      return (id) => {
        return this.goodsInfo.filter(
          (goods) => goods.id === id && goods.goods_name
        );
      };
    },
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  width: 300px;
  span {
    font-weight: bold;
    margin-bottom: 6px;
  }
  li {
    list-style-type: none;
  }
}
</style>
